import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image"
import {
  Row,
  Col,
  Button,
  Form,
  FormToggle,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const SuccessPageTemplate = ({ title, content }) => {
  return (
    <Layout style={{ backgroundColor: "#b5b8d7ff" }}>
      <SEO title="Pre-Lightschools Training" />
      <Row
        className="mx-0 w-100"
        style={{ marginTop: "-73px" }}
        className="training-section d-flex flex-row justify-content-center"
      >
        <Col className="px-0" xs={10} md={8} lg={6}>
          <div className="w-100 d-flex flex-column  ">
            <h1 className="sectionTitle">{title}</h1>
            <p>
              <ReactMarkdown source={content} linkTarget="_blank" />
            </p>
            <Link to="/" className="text-center">
              <Button size="lg" className="my-5 buttonStyle">
                Lightschools home page >
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

const SuccessPage = props => {
  const { frontmatter } = props.data.SuccessPageTemplate
  return (
    <SuccessPageTemplate
      title={frontmatter.title}
      content={frontmatter.content}
    />
  )
}

export default SuccessPage

export const pageQuery = graphql`
  query($id: String!) {
    SuccessPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
      }
    }
  }
`
